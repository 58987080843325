body{
  overflow-x: hidden;
}

.gutters {
  padding-left: 1rem;
  padding-right: 1rem;
}

#primary {
  padding: 0 30px;
}

a.nav-link {
  color: #106a37 !important;
  
}

.logo-text {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}

.navbar-brand {
  margin-right: 1.3rem;
}

.navbar-light .navbar-nav .nav-link {
  // padding: 2.8rem 0.5rem;
  margin-right: 40px;
}

.navbar-expand-md {
  padding: 0;
}

// #main-menu > .nav-item {
//   &:last-of-type {
//     background: $primary;
//     .nav-link {
//       color: $white !important;
//       margin-right: 0px;
//       padding: 2.8rem 3rem;
//     }
//   }
// }

#navbarNavDropdown {
  height: auto;
  @include media(">=xl") {
      height: 120px; 
      align-items: flex-end;
      padding-bottom: 1rem;
  }
}

.seta-row {
  margin-right: 40px;
  margin-top: .9rem;
  padding-bottom: .3rem;
  position: absolute;
  right: 9.7rem;
  top: 0px;
}

.search-row {
  height: 120px;
}


.custom-logo-link img {
    width: 95%;
}

.navbar-expand-xl{
  justify-content:space-between;
}

.Sub-content.text-white {
    font-size: 1.42rem;
}

h4 {
    font-weight: 300 !important;
    color: $primary !important;
    margin-bottom: 2rem;
}

// #main-menu > .nav-item:hover {
//   &:last-of-type {
//         opacity: .9;
//     .nav-link {
//       color: $white !important;
//       margin-right: 0px;
//       padding: 2.8rem 3rem;
//     }
//   }
// }

.custom-logo-subheading {
  font-size: 1.2rem;
  font-weight: 300;
  color: $primary;
}

.custom-logo-heading {
  font-size: 1.8rem;
  margin-bottom: 0;
  color: #106a37;
  font-weight: 300;
}

a.navbar-brand.custom-logo-link {
  border-right: 1px solid;
  color: #e4e4e4;
  padding-right: 15px;
  padding-left: 30px;
  padding-top: 1rem;
    padding-bottom: 1rem;
}

ul#main-menu {
  font-size: 1.2rem;
  color: $primary;
}

.post-edit-link {
  display: none;
}

#wrapper-footer {
  background: #000;
  color: #fafafa;
  text-align: center;
  padding: 20px 0 0;
  font-size: 0.8rem;
}

#primary, #right-sidebar {
  padding: 60px 30px;
}

.main-content-text.text-center {
  line-height: 1.7;
  max-width:775px;
  margin: auto;
}

.address img {
  width: 100%;
  max-width: 180px;
}

h3.address-title {
  font-size: 0.9rem;
  color: #106a37;
}

.address-content {
  font-size: 0.8rem;
  line-height: 1.1rem;
  font-weight: 500;
  color: #666666;
}

.right-img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: 100%;
  -o-object-position: center center;
  object-position: center center;
  max-height: 500px;
}

.hero-right-bottom-img {
  -o-object-fit: cover;
  object-fit: cover;
  height: 100%;
  width: auto;
  -o-object-position: center center;
  object-position: center center;
  max-height: 500px;
}


.hero-left-bottom {
  max-width: 450px;
  margin: 0 auto;
  z-index: 1;
}

.left {
    max-width: 500px;
    z-index: 1;
    padding-left: 30px;
	padding-right: 30px;
}

.hero-left-bottom .Sub-content.text-white {
  border-top: 0px;
  padding-top: 0;
  margin-top: 0;
}

.Sub-content.text-white {
  border-top: 2px white solid;
  margin: 20px 0;
  padding-top: 20px;
}

button.btn.btn-outline-primary,
a.btn.btn-outline-primary {
  padding: 10px 40px;
  background: transparent;
  color: #fff;
  border: 2px solid white;
  border-radius: 0;
  font-size: 1rem;
}

.carousel-indicators li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 43px;
  height: 43px;
  margin-right: 0px;
  margin-left: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #106a37 !important;
}

.carousel-indicators li::before {
  font-family: "Font Awesome 5 Free";
  content: "\f095";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}

.fp-slide {
  background-size: cover;
  background-position: center;
  > .row {
    height: 100%;
  }
  .fp-slide-container {
    > .row {
      height: 100%;
    }
  }
}

.fp-slider-dots {
  margin-top: -54px !important;
  position: relative;
}

.fp-slider-heading {
  font-size: 3.35rem;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  line-height: 1;
}

.fp-slider-subheading {
  font-size: 1.25rem;
  font-weight: 500;
  font-variation-settings: "wght" 500;
  line-height: 1.3;
}

.slider-area {
  display: block;
  width: 100%;
  height: 37.5rem;
  max-height: 60vh;
  min-height: 30rem;
}

.slick-list,
.slick-slide,
.slick-track {
  height: 100%;
}



.col-auto.d-flex.gutters.align-items-center.justify-content-center {
  flex-flow: column;
  width: 800px;
    max-width: 100%;
}

#fp-slider a.btn.btn-outline-primary {
  text-transform: uppercase;
}

#fp-slider button.btn.btn-primary.btn-slide-control {
  border-radius: 0;
  width: 3rem;
  height: 3rem;
  font-size: 1.8rem;
  border-left: 0px;
  border-right: 0px;
  line-height: 1rem;
  padding: 0px !important;
}

.adjustment-dot.row {
    position: relative;
    margin-top: -81px;
    width: 100%;
    padding-bottom: 60px;
    padding-top: 20px;
}

.bottom-hero-box.container,
.top-hero-box.container {
  position: relative;
}

/* .hero-column.col-lg-6.mt-5.d-flex.align-items-center:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #106a37;
      left: -52%;
} */

/* @include media("<lg"){
.hero-column.col-lg-6.mt-5.d-flex.align-items-center:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #106a37;
      left: 0%;
}
}

@include media(">lg"){
.hero-column.col-lg-6.mt-5.d-flex.align-items-center:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    bottom: 0;
    width: 100vw;
    background-color: #106a37;
    left: -337px;
}
} */

input.gform_button.button:hover {
  color: white;
  background:$primary;
  transition: 0.3s linear;
}


a.btn.btn-outline-primary:hover {
  background: #106a37;
  transition: .2s linear;
}

.slick-dots li button:hover {
  background-color: #054621;
}

.bg-fill.container-fluid {
    
    background: linear-gradient(to bottom, rgb(255, 255, 255) 12%,rgba(16,106,55,1) 12%);
    
}

@include media("<lg"){
.bg-fill.container-fluid {
    
    background: #106a37;
    
}
}

.hero-column-top.col-lg-6.mt-5.mb-5.pt-5.pb-5.my-auto:before {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  bottom: 0;
  width: 100vw;
  background-color: #106a37;
  left: -337px;
  z-index: -1;
}

.Sub-content.text-white.text-center {
  padding: 0 57px;
}


.left-alt.align-middle {
  padding-left: 30px;
  max-width: 420px;
  /* margin: 0 auto; */
  z-index: 1;
}

.contact-form.container {
  padding-bottom: 100px;
  padding-top: 100px;
}



.home .entry-content p {
    font-size: 1.42rem;
}




  figure.effect-ruby {
    border: 1px solid rgba(255, 255, 255, 0.5);
}



.phoneHolder i.icon-phone{
	color: #a7a9ac;
    font-size: 30px;
    top: 4px;
    position: relative;
}

.icon-phone:before {
    content: "\e800";
}

.fa-phone {
  color: white;
  font-size: 1.5em;
  padding-right: 1rem;
}

.detailContainer {
    padding: 50px;
    background: #24c7c4;
    display: block;
    color: #fff;
    font-size: 16px;
    line-height: 28px;
	}
	
	.detailContainer .detailRow {
    padding-bottom: 15px;
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
}
.detailContainer .detailHeading {
    color: $white;
	font-weight:bold;
}
.detailContainer .detailRow a, .detailContainer .detailRow a:hover {
    color: #fff;
    text-decoration: none;
}

.detailContainer.col-4.bg-primary {
    margin-left: -50px;
    margin-top: 50px;
}

.detailContainer .detailRow>span {
    display: block;
}

.stroke-transparent {
 -webkit-text-stroke: 1px #000;
 -webkit-text-fill-color: transparent;
}


.fc-day-header {
    background: rgba(247,247,247,.75);
    color: #000;
    letter-spacing: .1em;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1rem;
}

th.fc-day-header.fc-widget-header {
    border: none;
}



.site-footer .list-inline {
    margin: 0px;
}


nav.navbar.navbar-expand-xl.navbar-light {
    padding: 0px;
}

@media (min-width: 1400px) and (max-width: 1630px) {
.navbar-light .navbar-nav .nav-link{
		margin-right: 10px ;
	
}
}  


@media (min-width: 1300px) and (max-width: 1400px) {
.navbar-light .navbar-nav .nav-link{
		margin-right: 3px ;
	
}
}

@media (max-width: 1300px)  {

   a.navbar-brand.custom-logo-link {
    border-right: 1px solid;
    color: #e4e4e4;
    padding-right: 15px;
    padding-top: 15px;
    padding-left: 10px;
    padding-bottom: 15px;

}
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: #e4e4e4;
}

@media (max-width: 500px) {

nav.navbar.navbar-expand-xl.navbar-light img {
width: 80px;
}

.custom-logo-heading{
font-size:1.2rem;
}
.navbar-toggler {
    padding: 0px;
    font-size: 2rem;
	}
}


@media (min-width: 200px) and (max-width: 500px) {
.custom-logo-heading{
font-size:1.1rem;
}

.navbar-brand {
    margin-right: .2em;
}
	
}


@media (max-width: 1324px){
.navbar-light .navbar-nav .nav-link {
    padding: .6rem .5rem;
	text-align:center;
	margin-right: 0px;
    
}
#main-menu>.nav-item .nav-link{
padding: .6rem .5rem;
}

}

.dropdown-item{
color:$white;
}

ul.dropdown-menu.show {
    text-align: center;
    background:$primary;
    font-size: 1.2rem;
}

button.navbar-toggler {
    margin-right: 2rem;
}

@media (max-width: 530px){
button.navbar-toggler {
    margin-right: .1rem;
}
}

.dropdown-menu {
margin:0px;
border:0; 
}

// gform styling


.gform_body .gform_fields input, .gform_body .gform_fields .gfield textarea{
border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid #9d9ea2;
  padding: 14px 0px;
  background: transparent;
  border-radius: 0px;
  font-size: 18px;
  }
  
  select#input_2_5 {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    font-size: 1rem;
    background: #e4e4e459;
}

body .gform_wrapper_2 ul li.gfield {
    margin-top: 16px;
    
}

ul.gform_fields>li {
    padding: 0 6px;
}

.gform_body .gform_fields .gfield textarea {
  max-height: 60px;
}

input.gform_button.button {
    padding: 10px 40px;
    background: 0 0;
    color: $primary;
    border: 2px solid $primary;
    border-radius: 0;
    font-size: 1rem;
    float: right;
}

.ginput_container.ginput_container_text {
    margin-bottom: 1rem;
}

 .gform_body .gform_fields .gfield .gfield_label{
 color: #106a37;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 2px;
  /* text-transform: uppercase; */
  clip: unset;
  height: auto;
  margin-top: 20px;
  overflow: unset;
  width: 100%;
  position: relative;
  padding: 0;
  }
  

 
 //calander
 
 .event-details .event-title {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: .875em;
    color: #011b41;
}

.event-start, .event-end{
	font-size: .875em;
}

.event-details .event-info>p, .event-details .event-info .page-content {
    border-bottom: 2px solid #ebebeb;
    margin: 0;
    padding: 16px 0;
}
 
.event-details .event-info {
    border-top: 3px solid #ebebeb;
}

.event-details .event-heading {
    font-weight: 700;
    color: $primary;
    text-transform: uppercase;
    letter-spacing: .109375em;
    margin-bottom: .5em;
}

.modal-left {
    padding: 0 2rem;
}

.modal-header {
    border: 0;
    padding-bottom: 0;
    padding: 15px 30px 0;
}

#fullCalModal .modal-body {
    padding: 0 30px 30px;
}

a.nav-link {
    display: inline-block;
}


.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 0.8rem;
    clear: both;
    font-weight: 300;
    color: #fff;
    text-align: left;
    white-space: nowrap;
    background-color: #106a37;
    border: 0;
}

.dropdown-menu{
background-color:$primary;
}


span.detailHeading {
    width: 60px;
    display: inline-block;
}

#map {
    height: 540px;
    width: 100%;
}


.spacing {
    height: 5rem;
}


@media (max-width: 991px) {

.spacing {
    height: 0rem;
}
}

h3.gmap-info-title {
    font-size: 1.05rem;
    color: $primary;
}

.calendar-image {
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}



.timeline {
  position: relative;
  width: 100%;
  float: left;
  position: relative;
  padding: 0 0 60px 0;
  margin-top: 0;
  margin-top: 4px;

  .photoholder {
    position: absolute;
    right: 0;
    display: none;
  }

  .collapse.in .photoholder {
    float: right;
    width: 30%;
    padding: 0px 10px;
    display: block;
  }

  .panel {
    width: 100%;
  }

  .panel-collapse {
    width: 70%;
  }

  .photoholder img {
    width: 100%;
    height: auto;
  }

  .glyphicon.glyphicon-one-fine-dot:before {
    content: "\25cf";
    font-size: 1.5em;
    color: #0039a6;
  }

  .line {
    position: absolute;
    width: 2px;
    display: block;
    top: 32px;
    bottom: 0px;
    margin-left: 30px;
    background: #0039a6;
  }

  .separator {
    border-top: 1px solid #0039a6;
    padding: 5px;
    padding-left: 40px;
    font-style: italic;
    font-size: 0.9em;
    margin-left: 30px;
  }

  .line::before {
    top: -2px;
  }

  .line::after {
    bottom: -2px;
  }

  .line::before,
  .line::after {
    content: "";
    position: absolute;
    left: -6px;
    width: 0;
    height: 0;
    display: block;
    border-radius: 50%;
    background: #0039a6;
  }

  .panel {
    position: relative;
    margin: 10px 0px 0px 40px;
    clear: both;
    margin: 0 auto;
    padding: 10px 0px 0 40px;
    background: none;
  }

  .panel::before {
    position: absolute;
    display: block;
    top: 8px;
    left: -24px;
    content: "";
    width: 0px;
    height: 0px;
    border: inherit;
    border-width: 12px;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }

  .panel .panel-heading.icon * {
    font-size: 14px;
    vertical-align: middle;
    line-height: 40px;
  }

  .panel > .panel-heading .icon {
    position: absolute;
    left: 11px;
    /*display: block;*/
    width: 40px;
    height: 40px;
    padding: 0px;
    border-radius: 50%;
    text-align: center;
    float: left;
  }

  .panel-outline {
    border-color: transparent;
    background: transparent;
    box-shadow: none;
  }

  .panel-outline .panel-body {
    padding: 10px 0px;
  }

  .panel-default {
    border: 0;
  }

  .panel-default > .panel-heading {
    font-family: "Oswald", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 0;
    background: none;
    font-weight: 400;
  }

  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top: 0;
    padding-top: 0;
    border-bottom: 0;
  }

  h4.panel-title {
    font-size: 24px;
    border: 0;
  }

  .panel {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}


.fc-center .fc-button {
    width: auto;
    height: auto;
    padding: 0;
    border: 2px solid #106a37!important;
}

.fc-button {
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: .2s ease-in-out all;
    -o-transition: .2s ease-in-out all;
    transition: .2s ease-in-out all;
    display: inline-block;
    background: 0 0;
    border: 0;
    border-top: 2px solid #ebebeb;
    border-bottom: 2px solid #ebebeb;
    color: #fff;
    color: #011b41;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: .875em;
    border-radius: 0!important;
}


.container.calendar.mt-5.mb-5 {
    max-width: 1465px;
}


@media (max-width: 700px) {

.fc-center {
    width: 100% !important;
}

.fc-toolbar .fc-left {
    display:none;
}

.fc-scroller {
    height: 100% !important;
}
}

.entry-content p {
    margin-bottom: 2rem;
}


.news-row img {
  margin-right:10px;
  max-width: 400px;
  width:100%;
}

.news-row {
  display: flex;
  flex-direction: row;
      max-height: 400px;
	  height:100%;
  padding: 10px 0;
}

.news-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
      white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

h3.widget-title {
    font-size: 1rem;
    color: $primary;
}

#right-sidebar ul li {
    list-style-type: none;
}


#right-sidebar ul {
    padding-left: 15px;
}


//date styling

td.ui-datepicker-week-end {
    background: #ebebeb;
    border-radius: 1rem;
    color: gray;
    text-align: center;
    padding: 2px;
}

#ui-datepicker-div {
    /* width: 100%; */
    background: white;
    border: 1px solid green;
    padding: 2rem;
}

.ui-datepicker-calendar td {
    text-align: center;
}

.ui-datepicker-calendar td:hover {
    background: #106a37;
    border-radius: 1rem;
    color: white;
}

a.ui-state-default {
    margin: 4px;
	padding: 1px;
}

.ui-datepicker-calendar td:hover a{
color: white;
}

td.ui-datepicker-unselectable.ui-state-disabled {
    background: none;
}


.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}


a.ui-datepicker-prev.ui-corner-all, a.ui-datepicker-next.ui-corner-all {
    display: none;
}

 a.btn.btn-secondary.understrap-read-more-link {
    border: 2px solid #106a37;
    border-radius: 0;
    padding: .5em 1.5em;
    color: #106a37;
    background: #fff;
    font-size: 1rem;
    margin-top: 1.6rem;
}

 a.btn.btn-secondary.understrap-read-more-link:hover{
     color: #fff;
    background-color: #106a37;
    border-color: #106a37;
	}
	
	
	



.widget-title {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

@include media("<md") {
  .fp-slide {
    > .row {
      background: rgba(0, 0, 0, 0.45);
    }
    .fp-slider-subheading {
      font-weight: 600 !important;
      font-size: 2rem !important;
      line-height: 1.4;
    }
  }
}



.gform_fields select {
    border-left: none;
    border-right: none;
    border-top: none;
    border-radius: 0;
    font-size: 1rem;
    background: #e4e4e459;
}

//google button

.logo-plus-button {
    border: 1px solid #e4e4e4;
    padding: 8px;
}

.logo-plus-button-plus-icon {
    font-family: Arial;
    background-color: $primary;
    background-image: url(https://saintcolumbas.lcprojects.com.au/wp-content/uploads/2018/12/plus.jpg);
    background-position: center center;
    width: 18px;
    height: 18px;
}



.logo-plus-button, .logo-plus-button>div {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.logo-plus-button-lockup-text {
   
    font-size: 13px;
    opacity: .70;
}

.googimg {
    padding-top: 3px;
    padding-left: 5px;
}

#dark_top {
  background: #f1f1f1;
}

@include media("<lg"){
.left{
	max-width:100%;
	}
	
.bottom-hero-box.container{
		width:100%;
		max-width:none;
	} 
}

.bottom-hero-box img {
    max-width: 100%;
    height: auto;
    max-height: 400px;
    object-fit: cover;
    width: 100%;}

@include media(">lg"){	
	.wrapper__map {
    padding-left: 30px;
}
}
@include media("<lg"){	
	.wrapper__map {
    padding-left: 0;
}
}
@include media("<lg"){
.map.container.mt-5 {
    width: 100%;
    max-width: none;
}
}


.newsletter-template-default {
  .wrapper{
    background:#525659;
  }
  .container{
    background:white;
  }

  .entry-content {
    font-size: 1rem;
    padding:0 4rem;
    font-weight:400;  
    }
  }

  
  .principals-post {
    background: linear-gradient(90deg,#106a37 0,#fff 100%);
    color: #ffffff;
    padding: 1rem 1rem;
}



.new .grid figure {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 260px;
  background: #106a37;
  text-align: center;
  cursor: pointer;
}

.new .grid {
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  height: 260px;
}

.new .grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 0;
  width: 100%;
  object-fit: contain;
}


.new .grid:hover figure img {

  opacity: 0.4;
  
}



.new .effect-ruby:hover img {
  opacity: 0.4;
 transform: scale(1.4);
 @include media(">=lg") {
     transform: scale(1.2);
 }
  
}

.new .grid figure h2{
  font-size:1.5rem;
  min-height: 100%;
  margin-bottom:0;
}

.social-links a{
  text-align: center;
float: left;
width: 36px;
height: 36px;
border-radius: 100%;
margin-right: 7px; /*space between*/
background: $primary;
} 
.social-links a i{
font-size: 20px;
  line-height: 24px;
color: $white;
}

.login-bar{
  position:absolute;
  right:10%;
  margin-top:.5rem;
}

.seta-login{
  font-weight:bold;
}

#searchButton {
  
  width: 9.7rem;
  height: 100%;
  background: $primary;
  border: 0;
  border-radius: 0;
  color: white;
}

.search-menu.collapse.show .overlay {
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 3;
}
.search-menu .overlay {
  width: 100%;
  position: fixed;
  opacity: 0;
  -webkit-transition: opacity .4s ease;
  transition: opacity .4s ease;
  height: calc(100% - 120px);
}
.search-menu .overlay {
  background-color: rgba(0,0,0,.37);
}
.search-bar {
  z-index: 4;
  position: relative;
}

#searchCollapse {
  .card{
      border-radius: 0;
    .card-body {
      padding: 0;
      border-radius: 0;
    }
  }
  .card.card-body{
    padding: 0;
  }
}


.search-bar .form-control{
  height:4rem;
}

.row.search-row {
  background: red;
  /* height: 7rem; */
  
}

.seta-row{
  margin-right: 40px;
  margin-top: 0.9rem;
  padding-bottom: 0.3rem;
}

.hello.row {
  display: flex;
  flex-wrap: nowrap;
}

span.lastWord {
  font-size: 2rem;
  @include media(">=md") {
      font-size: 4rem;
  }
  
}

#menu-item-706, #menu-item-707 {
  display: block;
  @include media(">=lg") {
     display: none;  
  }
}
li#menu-item-706 {
  background: $primary;
   a.nav-link {
  color: white !important;
}
}

.single-newsletter{
  font-weight:400;
  font-size:1.1rem;
  line-height:1.5;
  color: #212529;
}

#toggle-btn {
  padding: 0rem 1rem;
  font-size: 1.9rem;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 0;
}


.newsletter-row{
  background: #eeeeee;
  font-size:1rem;
  border-bottom: 1px solid rgb(191, 186, 182);

  @include media(">=lg") {
   font-size:1.22rem;   
  }
  p{
    margin:0;
  }
  .newsletter-title{
    padding-left:.5rem;
  }
  .posted-date{
    background: #e3e3e3;
    padding-left:.5rem;
  }
}

.widget_recent_entries {
  font-size: 0.9rem;
}

.gform_wrapper_2{
  width:100%;
}

.single-newsletter .content-area img {
  display: block;
}

.single-newsletter h4 {
  margin: 2rem 0;
}

.single-newsletter #primary{
  padding:0;
  padding-right:30px;
  @include media(">=md") {
    padding: 60px 30px;   
  }
}

#newsletter-toggle.show-side-nav {
  -webkit-transform: translateX(85%);
  transform: translateX(85%);
  @include media(">=md") {
    -webkit-transform: translateX(80%);
    transform: translateX(80%);
    -webkit-transition: .2s linear;
    transition: .2s linear;
    will-change: transform;
  }
}

#newsletter-toggle {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: .2s linear;
  transition: .2s linear;
  will-change: transform; 
}

#newsletter-toggle {
  top: 5%;
  @include media(">=md") {
    min-width: 250px;
    max-width: 250px;
    position: fixed;
    top: 20%;
    right: 0;
    z-index: 8;
    background: $primary;
  }
}

.newsletter-toggle-content{
  padding-left:0;
  @include media(">=md") {
    padding-left:2rem;
  }
  
}

.closed {
  opacity: 1;
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(90deg);
    opacity: 0;
  }
}

.opened {
  .vertical {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
  }
  .horizontal {
    transition: all 0.5s ease-in-out;
    transform: rotate(-90deg);
    opacity: 1;
  }
}

.circle-plus {
    height: 4em;
    width: 4em;
    font-size: 1em;
    opacity:1;
    margin: .5rem 0;
}

.circle-plus .circle {
    position: relative;
    width: 2.55em;
    height: 2.5em;
   
    
}
.circle-plus .circle .horizontal {
    position: absolute;
    background-color: $white;
    width: 30px;
    height: 5px;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -2.5px;
}
.circle-plus .circle .vertical {
    position: absolute;
    background-color: $white;
    width: 5px;
    height: 30px;
    left: 50%;
    margin-left: -2.5px;
    top: 50%;
    margin-top: -15px;
}



