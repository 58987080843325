.fp-slide {
  background-size: cover;
  background-position: center;
  > .row {
    height: 100%;
	background: rgba(6,25,34,.25);
    
  }
  .fp-slide-container {
    > .row {
      height: 100%;
    }
  }
}

.fp-slider-dots {
  margin-top: -54px !important;
  position: relative;
}

.fp-slider-heading {
  font-size: 3.35rem;
  font-weight: 700;
  font-variation-settings: 'wght' 700;
  line-height: 1;
}

.fp-slider-subheading {
  font-size: 2.8rem;
  font-weight: 300;
  line-height: 1.3;
}

@include media("<=md") {
    .fp-slider-subheading {
    font-size: 1.25rem;
	}
} 

.fp-slider-subheading{
  font-family: 'Dancing Script', cursive;
}


