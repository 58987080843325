figure.effect-ruby {
  background-color: $primary;
}

figure.effect-ruby img {
  opacity: 0.5;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(1.15);
  transform: scale(1.02);
}

figure.effect-ruby:hover img {
  opacity: 0.7;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-ruby h2 {
  margin-top: 20%;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-ruby p {
  margin: 1em 0 0;
  padding: 0.5em 1.5em;
  border: 2px solid $white;
  opacity: 1;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: translate3d(0, 20px, 0) scale(1.02);
  transform: translate3d(0, 20px, 0) scale(1.02);
  width: 60%;
}

figure.effect-ruby:hover h2 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, -20px, 0);
}

figure.effect-ruby:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1);
}

.grid {
  overflow: hidden;
  margin: 0;
  padding: 0 0 0 0;
  width: 100%;
  list-style: none;
  text-align: center;
  height: 400px;
}

.grid figure {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  margin: 0em;
  width: 100%;
  height: 400px;
  background: $primary;
  text-align: center;
  cursor: pointer;
}

.grid figure img {
  position: relative;
  display: block;
  min-height: 100%;
  opacity: 0.4;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.grid figure figcaption {
  padding: 2em;
  color: $white;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
  pointer-events: none;
}

.grid figure figcaption,
.grid figure a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid figure a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

.grid figure h2 {
  font-weight: 300;
}

.grid figure h2,
.grid figure p {
  margin: 0;
}

.grid figure p {
  letter-spacing: 1px;
  font-size: 68.5%;
}

figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  flex-flow: column;
}

.grid figure h2 {
  margin-bottom: 5rem;
  font-size: 2rem;
  text-transform: none;
  min-height: 4.75rem;
  display: flex;
  align-items: center;
}
p {
  margin-bottom: 10px;
  margin-top: 10px;
}

@media (min-width: 1410px) and (max-width: 1763px) {
  figure.effect-ruby p {
    width: 80%;
  }
}

@media (min-width: 1300px) and (max-width: 1410px) {
  figure.effect-ruby p {
    width: 85%;
    padding: 0.5em 1em;
  }
}

@media (min-width: 768px) and (max-width: 1410px) {
  figure.effect-ruby p {
    width: 100%;
    padding: 0.5em 0.2em;
  }

  .grid figure p {
    letter-spacing: 1px;
    font-size: 50.5%;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  figure.effect-ruby p {
    width: 85%;
    padding: 0.5em 1em;
  }
}

@include media("<md") {
  figure.effect-ruby p {
    width: 85%;
    padding: 0.5em 1em;
  }

  .grid figure p {
    letter-spacing: 1px;
    font-size: 50.5%;
  }
}

@media (min-width: 200px) and (max-width: 500px) {
  figure.effect-ruby p {
    width: 100%;
    padding: 0.5em 0.8em;
  }
}

.adjustment.d-flex.flex-column {
  width: 100%;
  height: 100%;
}
