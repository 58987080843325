.gform_fields {
  @extend .list-unstyled;
  @extend .row;

  input,
  select,
  textarea {
    @extend .form-control;
  }

  textarea {
    height: auto;
  }
}

.gfield_description {
  @extend .alert;
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.validation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_button {
  @extend .btn;
}

.gfield_required {
  color: $alert-danger-text;
}

.gform_wrapper ul.gfield_radio li,
.gform_wrapper ul.gfield_checkbox li {
  @extend .form-check;
}

.gform_validation_container {
  display: none;
}

.gfield_error .ginput_container {
  margin-bottom: 10px;
}

.gform_wrapper .ld-full-width {
  width: 100%;
  max-width: 100%;
  display: inline-block;
}

.gform_wrapper .ld-left-third {
  width: 100%;
  max-width: 33.33333%;
  display: inline-block;
}

@media (max-width: 767px) {
  .gform_wrapper .ld-left-third {
    width: 100%;
    max-width: 100%;
  }
}

.gform_wrapper .ld-middle-third {
  width: 100%;
  max-width: 33.33333%;
  display: inline-block;
}

@media (max-width: 767px) {
  .gform_wrapper .ld-middle-third {
    width: 100%;
    max-width: 100%;
  }
}

.gform_wrapper .ld-right-third {
  width: 100%;
  max-width: 33.33333%;
  display: inline-block;
}

@media (max-width: 767px) {
  .gform_wrapper .ld-right-third {
    width: 100%;
    max-width: 100%;
  }
}

.gform_wrapper .ld-left-half {
  width: 100%;
  max-width: 50%;
  display: inline-block;
}

@media (max-width: 767px) {
  .gform_wrapper .ld-left-half {
    width: 100%;
    max-width: 100%;
  }
}

.gform_wrapper .ld-right-half {
  width: 100%;
  max-width: 50%;
  display: inline-block;
}

@media (max-width: 767px) {
  .gform_wrapper .ld-right-half {
    width: 100%;
    max-width: 100%;
  }
}
