.fc-center > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.fc-center {
  width: calc(100% - 344px);
}

.fc-content {
  text-overflow: ellipsis;
  padding: 5px 10px;
}

.fc-center h2 {
  font-size: 1.42rem;
  margin: 0 20px;
}
.fc-center {
  font-size: 1.42rem;
}

.fc-toolbar.fc-header-toolbar {
  display: flex;
  flex-flow: row;
  .fc-right {
    display: none !important;
  }
}

.importantdate-event {
  background: #607d8b;
  color: #fff !important;
  font-size: 16px;
  border-color: #9a9a9a;
  font-weight: 400;
}

.stcolumbas-event {
  background: #106a37;
  color: #fff !important;
  font-size: 16px;
  border-color: #9a9a9a;
  font-weight: 400;
}

.calendar-ymd {
  background: #0277bd;
  color: #fff !important;
  font-size: 16px;
  border-color: #9a9a9a;
  font-weight: 400;
}

@include media(">=xl") {
  .page-id-102 #content {
    max-width: 95%;
  }
}

// Add Event to Calendar SCSS

// prefix: a2cldr

$white: #fff;
$accent-color: #032c53;
$grey: #f3f3f3;

.a2cldr {
  height: 46px;
  width: 246px;
  position: relative;
  color: $accent-color;
  font-family: sans-serif;

  .a2cldr-btn {
    width: 246px;
    height: 46px;
    display: inline-block;
    border: 2px solid $accent-color;
    padding: 12px 18px;
    text-align: left;
    cursor: pointer;
    /*
    -webkit-transition: all .2s ease-in-out;
       -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
         -o-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
    */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    &:before {
      // content: '+';
      display: inline-block;
      float: right;
      position: absolute;
      top: 0;
      right: 16px;
      font-size: 24px;
      line-height: 46px;
    }
  }

  // &:hover,
  &.active {
    .a2cldr-btn {
      color: $white;
      background-color: $accent-color;

      &:before {
        // content: 'x';
        font-size: 20px;
        line-height: 44px;
      }
    }

    .a2cldr-list {
      display: block;
    }
  }
}

.a2cldr-list {
  position: relative;
  z-index: 2;
  padding: 0 12px;
  display: none;
  background-color: $white;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(186, 186, 186, 1);
  -moz-box-shadow: 0px 8px 20px 0px rgba(186, 186, 186, 1);
  box-shadow: 0px 8px 20px 0px rgba(186, 186, 186, 1);
}

.a2cldr-item {
  list-style: none;
  padding: 14px 0;
  border-bottom: 1px solid $grey;

  a {
    color: $accent-color;
    text-decoration: none;
    width: 100%;
    display: block;

    &:hover {
      color: $accent-color;
      text-decoration: underline;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
}
